import React, { useState } from "react";

const MemeGallery = () => {
  const [activeTab, setActiveTab] = useState("memes"); // State to track active tab

  // Array of image URLs (replace with your actual image paths)
  const images = [
    "meme1.jpg",
    "meme2.jpg",
    "meme3.jpg",
    "meme4.jpg",
    "meme5.jpg",
    "meme6.jpg",
    "meme7.jpg",
    "meme8.jpg",
    "meme9.jpg",
    "meme10.jpg",
  ];

  // Array of video URLs (replace with your actual video paths)
  const videos = [
    "endorsement1.MP4",
    "endorsement2.MP4",
    "endorsement3.MP4",
    // Add more video URLs as needed
  ];

  return (
    <div className="py-10 bg-white">
      {/* Header Section */}
      <div className="text-center mb-10">
        <h2 className="text-6xl font-bold">CONTENT</h2>
        <a
          href="https://x.com/gevinnewsum" // Replace with the actual link to "X"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 text-xl mt-2 inline-block hover:underline"
        >
          See more on X
        </a>
      </div>

      {/* Tabs Section */}
      <div className="flex justify-center mb-10">
        <button
          className={`px-6 py-2 mx-2 text-lg font-semibold ${
            activeTab === "memes"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-black"
          }`}
          onClick={() => setActiveTab("memes")}
        >
          Memes
        </button>
        <button
          className={`px-6 py-2 mx-2 text-lg font-semibold ${
            activeTab === "endorsements"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-black"
          }`}
          onClick={() => setActiveTab("endorsements")}
        >
          Endorsements
        </button>
      </div>

      {/* Content Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {activeTab === "memes" ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {images.map((src, index) => (
              <div key={index} className="aspect-w-1 aspect-h-1">
                <img
                  src={src}
                  alt={`Meme ${index + 1}`}
                  className="object-cover w-full h-full rounded-lg shadow-md"
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-wrap justify-center gap-4">
            {videos.map((src, index) => (
              <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
                <video className="w-full h-auto rounded-lg shadow-md" controls>
                  <source src={src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MemeGallery;
