import React from "react";

const About = () => {
  return (
    <div className="px-6 md:px-40 flex flex-col md:flex-row p-10 gap-12 w-full justify-between mb-8 items-center">
      <div className="md:flex-1 text-center md:text-left">
        <h1 className="text-4xl md:text-5xl mb-4 font-semibold">wut da heck is $newsum?</h1>
        <p className="text-xl md:text-2xl">
          th yeer iz 2024. america is ridden with cryme and led by non-chad
          poopy-pants jeo boden. america needs hero. america needs gevin newsum.
          wif good luks, striking intlect nd a deep luv fur homeles tents and
          wildfyres, gevin newsum wil save america. $newsum wil power new econmy
          wher each amercan pays 80% taxes nd must eat fentanyl fir brekfest.
          gevin save america!
        </p>
      </div>
      <img
        className="w-64 md:w-100 h-64 md:h-80 rounded-lg object-cover"
        src="captin-newsum.JPG"
        alt="Captain Newsum"
      />
    </div>
  );
};

export default About;
