import React, { useEffect } from "react";

const BurnCount = () => {
  useEffect(() => {
    // Check if the user is on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // If it's a mobile device, pause the video and remove autoplay
    if (isMobile) {
      const videoElement = document.getElementById("background-video");
      if (videoElement) {
        videoElement.pause();
        videoElement.removeAttribute("autoplay");
      }
    }
  }, []);

  return (
    <div className="relative flex flex-col items-center justify-center gap-6 py-10 overflow-hidden">
      {/* Background Video for larger screens */}
      <video
        id="background-video"
        className="absolute inset-0 w-full h-full object-cover z-0 hidden md:block"
        src="fire.mov"
        autoPlay
        loop
        muted
      />

      {/* Background Image for mobile screens */}
      <div
        className="absolute inset-0 w-full h-full bg-cover bg-center z-0 md:hidden"
        style={{ backgroundImage: "url(fire.jpg)" }} // Replace with your static image
      ></div>

      {/* Overlay for background darkening if needed */}
      <div className="absolute inset-0 bg-black opacity-30 z-10"></div>

      {/* Content on top of the video */}
      <div className="relative z-20 text-center text-white">
        <h2 className="text-4xl md:text-6xl font-bold leading-tight">
          BURN TRACKER 🔥
        </h2>
        <p className="text-2xl md:text-4xl mt-2 font-semibold">
          103,001,720 (~10% of supply)
        </p>
        <div className="w-full flex justify-center mt-4">
          <img
            className="w-40 md:w-60 lg:w-80 h-auto"
            src="jeo-fire.png"
            alt="Burn Fire"
          />
        </div>
      </div>
    </div>
  );
};

export default BurnCount;
