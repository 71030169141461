import React, { useState } from "react";

const CA = () => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 5000);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  const caText = "7AH1idoJMBEQtSUt4cmsrvAS1m6DwzniJh3jC1pxpump";

  return (
    <div className="flex flex-col items-center justify-center space-y-4 bg-blue-700 p-6 text-white mb-8">
      {/* Adjust font size and ensure wrapping for different screen sizes */}
      <div className="text-center break-words px-2 text-sm sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold">
        CA: {caText}
      </div>
      <button
        onClick={() => handleCopy(caText)}
        className="text-lg sm:text-lg md:text-2xl lg:text-3xl relative flex items-center justify-center"
        style={{ width: "100px", height: "60px"}} // Fixed size to prevent layout shif
      >
        {isCopied ? (
          <img
            className="absolute w-16 h-16"
            src="thumbs-up.png"
            alt="Thumbs up"
            style={{ top: "5%" }} // Push the image down slightly
          />
        ) : (
          <p className="text-lg sm:text-lg md:text-2xl lg:text-3xl">copy</p>
        )}
      </button>
    </div>
  );
};

export default CA;
