import React from "react";
import "./App.css"; // Ensure you import the CSS where you defined the animation
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";

const NavBar = () => {
  return (
    <div className="mt-6 flex justify-between items-center px-8 mb-8">
      <img
        className="w-20 rotate-animation" // Apply the animation class directly
        src="nusum-transparent.png"
        alt="Spinning logo"
      />
      <h2 className="text-6xl md:text-7xl font-semibold font-">$NEWSUM</h2>

      <div className="flex space-x-6 mb-6 md:mb-0 hidden md:flex">
        {/* Hidden on small screens and visible on medium and larger screens */}
        <a
          href="https://x.com/gevinnewsum"
          target="_blank"
          rel="noopener noreferrer"
          className="text-black"
        >
          <FaXTwitter className="w-8 h-8" />
        </a>
        <a
          href="https://t.me/gevinnewsum24"
          target="_blank"
          rel="noopener noreferrer"
          className="text-black"
        >
          <FaTelegramPlane className="w-8 h-8" />
        </a>
      </div>
    </div>
  );
};

export default NavBar;
