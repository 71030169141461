
import './App.css';
import NavBar from './NavBar';
import CA from './CA';
import TopCarousel from './carousel/TopCarousel';
import About from './About';
import HowToBuy from './HowToBuy';
import BurnCount from './BurnCount';
import MemeGallery from './MemeGallery';
import Footer from './Footer';
import Buttons from './Buttons';

function App() {
  return (
    <div className="App font-chilanka">
      <NavBar />
      <img className="w-full" src="newsum-banner.jpg" />
      <div className="flex flex-col mt-10">
        <TopCarousel />
        <Buttons />

        <CA />

        <About />
        <HowToBuy />
        <BurnCount />
        <MemeGallery />
        <Footer />
      </div>
    </div>
  );
}

export default App;
