import React from "react";

const HowToBuy = () => {
  return (
    <div className="relative text-white bg-blue-800 h-auto flex items-start justify-center py-10">
      {/* Background Image and Overlay */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(camp.jpeg)`,
          filter: "brightness(0.5) contrast(1.2) blur(2px)"
        }}
      >
        <div className="absolute inset-0 bg-blue-800 opacity-30"></div> {/* Blue Overlay */}
      </div>

      {/* Content */}
      <div className="relative px-10 py-12 w-full max-w-6xl flex flex-col md:flex-row gap-10">
        {/* Left Section */}
        <div className="flex-1 flex flex-col items-center justify-start text-center md:text-left">
          <h2 className="text-6xl md:text-8xl font-bold mb-6">how 2 buy</h2>
          <img
            className="w-100 mb-6"
            src="gevin-evil-Photoroom.png"
            alt="Character Image"
          />
        </div>

        {/* Right Section */}
        <div className="flex-1 pl-0 md:pl-10">
          <h2 className="text-3xl md:text-4xl font-bold mb-2">CREATE A WALLET</h2>
          <p className="mb-4 text-lg md:text-xl font-semibold">
            If you're using a web browser like Chrome or Firefox, download a
            wallet extension. If on a mobile device like an iPhone or Android,
            download your preferred wallet from your device's app store. Gevin
            endorses Phantom.
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://phantom.app/download"
          >
            <button className="bg-phantom hover:bg-blue-300 text-black font-bold py-2 px-4 rounded mb-6">
              Download Phantom
            </button>
          </a>

          <h2 className="text-3xl md:text-4xl font-bold mt-6 mb-2">OBTAIN SOL</h2>
          <p className="mb-4 text-lg md:text-xl font-semibold">
            You need SOL to buy $newsum. You can either buy it directly in your
            Phantom wallet, or acquire it on another exchange like Binance or
            Coinbase and send it to your wallet.
          </p>

          <h2 className="text-3xl md:text-4xl font-bold mt-6 mb-2">GO TO RAYDIUM</h2>
          <p className="mb-4 text-lg md:text-xl font-semibold">
            Connect your wallet to the Raydium site. Paste the $newsum token
            address (which can be found at the top of the site) into Raydium,
            select $newsum, and confirm. When Raydium prompts you for a wallet
            signature, sign.
          </p>

          <h2 className="text-3xl md:text-4xl font-bold mt-6 mb-2">SWAP FOR $NEWSUM</h2>
          <p className="mb-4 text-lg md:text-xl font-semibold">
            Swap your $SOL for $newsum. Make sure your slippage is configured
            correctly!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowToBuy;
