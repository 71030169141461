import React from "react";

const Buttons = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-10 md:gap-16 justify-center mt-8 mb-16 font-semibold">
      <button className="text-3xl md:text-5xl hover:underline">
        <a
          target="_blank"
          href="https://raydium.io/swap/?inputCurrency=HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4&outputCurrency=sol&fixed=in&inputMint=sol&outputMint=7AH1idoJMBEQtSUt4cmsrvAS1m6DwzniJh3jC1pxpump"
        >
          Buy $newsum
        </a>
      </button>
      <button className="text-3xl md:text-5xl hover:underline">
        <a
          target="_blank"
          href="https://dexscreener.com/solana/7AH1idoJMBEQtSUt4cmsrvAS1m6DwzniJh3jC1pxpump"
        >
          Dexscreener
        </a>
      </button>
      <button className="text-3xl md:text-5xl hover:underline">
        <a
          target="_blank"
          href="https://www.dextools.io/app/en/solana/pair-explorer/3nDvaAP4g5u6XGBTMGzPzNqfwq76BgZx2D28gnTDKEtP?t=1719601207110"
        >
          DEXTools
        </a>
      </button>
      <button className="text-3xl md:text-5xl hover:underline">
        <a target="_blank" href="https://x.com/gevinnewsum">
          Newsum on X
        </a>
      </button>
    </div>
  );
};

export default Buttons;
