import React from "react";
import "./TopCarousel.css";

const TopCarousel = () => {
  return (
    <div className="carousel-container w-full mb-6">
      <div className="carousel-content">
        {/* First set of images */}
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image1"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image2"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image3"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image4"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image5"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image6"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image7"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image8"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image9"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image10"
        />

        {/* Duplicate set of images for smooth transition */}
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image1"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image2"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image3"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image4"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image5"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image6"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image7"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image8"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image9"
        />
        <img
          className="w-20 carousel-image"
          src="nusum-transparent.png"
          alt="image10"
        />
      </div>
    </div>
  );
};

export default TopCarousel;
